import React from 'react';
import useTranslationHook from '../../useTranslationHook';

const History = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <section class='page-section' id='about'>
        <div class='container'>
          <div class='text-center'>
            <h2 class='section-heading text-uppercase'>
              {t('page.history.heading1')}
            </h2>
            <h3 class='section-subheading text-muted'>
              {t('page.history.heading2')}
            </h3>
          </div>
          <ul class='timeline'>
            <li>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src={require('../../assets/images/logo.png')}
                  alt='Nikhra Chouk'
                />
              </div>
              <div class='timeline-panel'>
                <div class='timeline-heading'>
                  <h4 class='subheading'>
                    {t('page.history.timeline1.heading')}
                  </h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>{t('page.history.timeline1.text')}</p>
                </div>
              </div>
            </li>
            <li class='timeline-inverted'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src={require('../../assets/images/ground.jpg')}
                  alt='Ground'
                />
              </div>
              <div class='timeline-panel'>
                <div class='timeline-heading'>
                  <h4 class='subheading'>
                    {t('page.history.timeline2.heading')}
                  </h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>{t('page.history.timeline2.text')}</p>
                </div>
              </div>
            </li>
            <li>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src={require('../../assets/images/main-building.jpg')}
                  alt='...'
                />
              </div>
              <div class='timeline-panel'>
                <div class='timeline-heading'>
                  <h4 className='subheading'>
                    {t('page.history.timeline3.heading')}
                  </h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>{t('page.history.timeline3.text')}</p>
                </div>
              </div>
            </li>
            <li class='timeline-inverted'>
              <div class='timeline-image'>
                <img
                  class='rounded-circle img-fluid'
                  src={require('../../assets/images/gate.jpg')}
                  alt='...'
                />
              </div>
              <div class='timeline-panel'>
                <div class='timeline-heading'>
                  <h4 class='subheading'>
                    {t('page.history.timeline4.heading')}
                  </h4>
                </div>
                <div class='timeline-body'>
                  <p class='text-muted'>{t('page.history.timeline4.text')}</p>
                </div>
              </div>
            </li>
            <li class='timeline'>
              <div class='timeline-image'>
                <h4>{t('page.history.section5.center.text')}</h4>
              </div>
              <div class='timeline-panel'>
                <div class='timeline-body'>
                  <p class='text-muted'>{t('page.history.summary.text')}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default History;
