import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import History from './components/about/History';
import VisionMission from './components/about/VisionMission';
import News from './components/about/News';
import LatestUpdate from './components/about/LatestUpdate';
import People from './components/People';
import Doner from './components/people/Doner';
import ManagingCommittee from './components/people/ManagingCommittee';
import AdvisoryCommittee from './components/people/AdvisoryCommittee';
import WorkingCommittee from './components/people/WorkingCommittee';
import Plannings from './components/Plannings';
import FuturePlanning from './components/plannings/FuturePlanning';
import CurrentProgress from './components/plannings/CurrentProgress';
import FundsExpenses from './components/plannings/FundsExpenses';
import Donation from './components/Donation';
import Contact from './components/Contact';
import Gallery from './components/Gallery';

import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import BookReader from './components/bookReader';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />}>
            <Route path='history' element={<History />} />
            <Route path='vision-mission' element={<VisionMission />} />
            <Route path='news' element={<News />} />
            <Route path='latest-update' element={<LatestUpdate />} />
          </Route>
          <Route path='/people' element={<People />}>
            <Route path='doner' element={<Doner />} />
            <Route path='managing-committee' element={<ManagingCommittee />} />
            <Route path='advisory-committee' element={<AdvisoryCommittee />} />
            <Route path='working-committee' element={<WorkingCommittee />} />
          </Route>
          <Route path='/plannings' element={<Plannings />}>
            <Route path='future-planning' element={<FuturePlanning />} />
            <Route path='current-progress' element={<CurrentProgress />} />
            <Route path='funds-expenses' element={<FundsExpenses />} />
          </Route>
          <Route path='donation' element={<Donation />} />
          <Route path='book-reader' element={<BookReader />} />
          <Route path='contact' element={<Contact />} />
          <Route path='gallery' element={<Gallery />} />
          {/* Nested routes for About, People, Plannings, etc., should also follow this pattern */}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
