import React from 'react';

const LatestUpdate = () => (
  <div>
    <h2>Latest Update</h2>
    <p>Recent updates and developments in the Madrasa.</p>
  </div>
);

export default LatestUpdate;
