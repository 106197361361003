import React, { useState, useEffect } from 'react';
//import { Document, Page } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const PdfReader = ({ selectedBookId, pdfFiles }) => {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    if (selectedBookId) {
      const selectedPdf = pdfFiles.find((pdf) => pdf.id === selectedBookId);
      if (selectedPdf) {
        setDocument(selectedPdf.url);
      }
    }
  }, [selectedBookId, pdfFiles]);

  return (
    <div className='pdf-reader'>
      {document && (
        <Document file={document}>
          <Page size='A4' />
        </Document>
      )}
    </div>
  );
};

export default PdfReader;
