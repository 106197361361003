import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RamadanTimings = () => {
  const [location, setLocation] = useState({ lat: null, long: null });
  const [timings, setTimings] = useState({ Sehri: '', Iftar: '' });
  const [hijriDate, setHijriDate] = useState('');
  let today = new Date();

  let date =
    today.getDate() +
    '-' +
    parseInt(today.getMonth() + 1) +
    '-' +
    today.getFullYear();

  //console.log(date);

  useEffect(() => {
    // Get user's location
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLocation({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
      },
      () => {
        console.error('Location access denied.');
      }
    );
  }, []);

  useEffect(() => {
    if (location.lat && location.long) {
      // Fetch Ramadan timings
      const fetchTimings = async () => {
        try {
          const response = await axios.get(
            //`http://api.aladhan.com/v1/timingsByCity?city=YourCityName&country=YourCountryCode&method=2&school=1`
            `http://api.aladhan.com/v1/timings/${date}?latitude=${location.lat}&longitude=${location.long}&method=2`
          );
          // Alternatively, use the latitude and longitude for more accuracy:

          const {
            data: {
              data: { timings },
            },
          } = response;
          setTimings({ Sehri: timings.Fajr, Iftar: timings.Maghrib });
        } catch (error) {
          console.error('Error fetching prayer times:', error);
        }
      };

      fetchTimings();
    }
  }, [location]);
  useEffect(() => {
    const fetchHijriDate = async () => {
      try {
        const response = await axios.get(
          `http://api.aladhan.com/v1/gToH/${date}`
        );
        const hijri = response.data.data.hijri;
        setHijriDate(`${hijri.day} ${hijri.month.en} ${hijri.year} AH`);
      } catch (error) {
        console.error('Error fetching Hijri date:', error);
      }
    };

    fetchHijriDate();
  }, []);

  return (
    <div>
      <h4>Sehri: {timings.Sehri}</h4>
      <h4>Iftar: {timings.Iftar}</h4>
    </div>
  );
};

export default RamadanTimings;
