import React from 'react';

const News = () => (
  <div>
    <h2>News</h2>
    <p>Latest news and updates related to the Madrasa.</p>
  </div>
);

export default News;
