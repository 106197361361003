import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import Footer from './Footer';
import LanguageSelector from './LanguageSelector';
import useTranslationHook from '../useTranslationHook';

const Layout = () => {
  const { t } = useTranslationHook();
  const handleSearch = (e) => {
    e.preventDefault();
    // Perform the search logic here
    // You can access the search input value using e.target.elements.search.value
    console.log('Searching for:', e.target.elements.search.value);
  };
  return (
    <>
      <nav
        className='navbar navbar-expand-lg navbar-dark fixed-top'
        id='mainNav'
        style={{
          position: 'fixed', // Fix the navbar position
          top: 0, // Align to the top
          width: '100%', // Ensure it spans the full viewport width
          backgroundColor: '#1B1A55', // Dark background
          color: '#ffffff', // Light text (applied to nav items through CSS classes or inline styles below)
          zIndex: 1030, // Ensure navbar stays on top of other content
        }}
      >
        <div className='container-fluid' style={{ marginLeft: '50' }}>
          <Link className='navbar-brand' to='/'>
            <img
              src={require('../assets/images/logo.png')}
              width='40'
              height='50'
              alt='Logo'
            />
          </Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNavDropdown'
            aria-controls='navbarNavDropdown'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            {t('menu.menu')}
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNavDropdown'>
            <ul className='navbar-nav text-uppercase ms-auto py-4 py-lg-0'>
              <li className='nav-item'>
                <Link className='nav-link' to='/'>
                  <i class='fa fa-home' aria-hidden='true'></i>
                  <span>{t('menu.home')}</span>
                </Link>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownMenuLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i class='fa fa-info-circle' aria-hidden='true'></i>
                  {t('menu.about')}
                </Link>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='navbarDropdownMenuLink'
                >
                  <li>
                    <Link className='dropdown-item' to='/about/history'>
                      {t('menu.aboutSub.history')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/vision-mission'>
                      {t('menu.aboutSub.visionMission')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/news'>
                      {t('menu.news')}
                    </Link>
                  </li>
                  <li>
                    <Link className='dropdown-item' to='/about/latest-update'>
                      {t('menu.aboutSub.latest_update')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownPeopleLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i class='fa fa-users' aria-hidden='true'></i>
                  <span>{t('menu.people')}</span>
                </Link>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='navbarDropdownPeopleLink'
                >
                  <li>
                    <Link className='dropdown-item' to='/people/doner'>
                      {t('menu.peopleSub.doner')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/people/managing-committee'
                    >
                      {t('menu.peopleSub.managingCommittee')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/people/advisory-committee'
                    >
                      {t('menu.peopleSub.advisoryCommittee')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/people/working-committee'
                    >
                      {t('menu.peopleSub.workingCommittee')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className='nav-link dropdown-toggle'
                  to='#'
                  id='navbarDropdownPlanningLink'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i class='fa fa-users' aria-hidden='true'></i>
                  <span>{t('menu.planning')}</span>
                </Link>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='navbarDropdownPlanningLink'
                >
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/plannings/future-planning'
                    >
                      {t('menu.planningSub.FuturePlanning')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/plannings/current-progress'
                    >
                      {t('menu.planningSub.CurrentProgress')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to='/plannings/funds-expenses'
                    >
                      {t('menu.planningSub.FundsExpenses')}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/donation'>
                  <i class='fa fa-solid fa-hand-holding-dollar'></i>
                  <span>{t('menu.donation')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/book-reader'>
                  <i class='fa fa-solid fa-image'></i>
                  <span class='ml-4'>{t('menu.downloads')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/contact'>
                  <i class='fa fa-address-book' aria-hidden='true'></i>
                  <span>{t('menu.contact')}</span>
                </Link>
              </li>
              <li className='nav-item'>
                <Link className='nav-link' to='/gallery'>
                  <i class='fa fa-solid fa-image'></i>
                  <span class='ml-4'>{t('menu.gallery')}</span>
                </Link>
              </li>
              {/* Additional main menu items */}
            </ul>
            <ul class='nav navbar-nav navbar-right'>
              <div className='d-flex'>
                <LanguageSelector />
              </div>
            </ul>
            <ul style={{ display: 'none' }}>
              <form className='d-flex' onSubmit={handleSearch}>
                <input
                  className='form-control me-2'
                  type='search'
                  placeholder='Search'
                  aria-label='Search'
                  name='search'
                />
                <button className='btn btn-outline-success' type='submit'>
                  <i class='fa fa-search' aria-hidden='true'></i>
                </button>
              </form>
            </ul>
          </div>
        </div>
      </nav>

      <main className='mt-6'>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
