import React from 'react';
import { Link } from 'react-router-dom';
import useTranslationHook from '../../useTranslationHook';

const Doner = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <h3>Doners</h3>
      <p>
        <div className='card-body'>
          <h2>{t('page.people.doner.heading1')}</h2>:
          <div className='h4'>{t('page.people.doner.text1')}</div>
          <br />
          <hr />
          <p className='h4 text-center'>{t('page.people.doner.heading2')} </p>
          <div>
            <h5>{t('page.people.doner.subhead2')}:</h5>{' '}
            <p>{t('page.people.doner.text2')}</p>
          </div>
        </div>
        <div className='h5'>
          {t('page.people.doner.donation_send_text')}
          {': '}
          <Link to='/donation'>donation page </Link>
        </div>
      </p>
    </div>
  );
};

export default Doner;
