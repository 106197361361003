import React from 'react';
import AppRouter from './AppRouter';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
//import { useTranslation } from 'react-i18next';

function App() {
  //const { t } = useTranslation();
  return <AppRouter />;
}

export default App;
