import React from 'react';
import { Carousel, FloatingLabel } from 'react-bootstrap';
import RamadanTimings from './Ramadan';
import HijriCalendar from './HijriCal';
import useTranslationHook from '../useTranslationHook';

const Home = () => {
  const { t } = useTranslationHook();
  return (
    <>
      <div>
        {/* Carousel */}
        <Carousel>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={require('../assets/images/slider/main_gate.png')}
              alt='Madarsa Ashrafia Siddiquia Nikhra'
            />
            <Carousel.Caption>
              <h3>Madarsa Ashrafia Siddiquia Nikhra</h3>
              <p>A gateway to your success!.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={require('../assets/images/slider/slider-bg-2.jpg')}
              alt='Second slide'
            />
            <Carousel.Caption>
              <h3>Nikhra South</h3>
              <p>A Beautiful view of Village. In a lush green region</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={require('../assets/images/slider/slider-bg-3.jpg')}
              alt='Third slide'
            />
            <Carousel.Caption>
              <h3>South West region </h3>
              <p>Another view of village.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={require('../assets/images/slider/Mustard.png')}
              alt='Third slide'
            />
            <Carousel.Caption>
              <h3>A beutiful Mustard Field </h3>
              <p>Mustard Field in the village. A natural beauty.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src={require('../assets/images/slider/slider-bg-3.jpg')}
              alt='Third slide'
            />
            <Carousel.Caption>
              <h3>South West region </h3>
              <p>Another view of village.</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

        {/* Sections Below Carousel */}
        <section class='page-section' id='services'>
          <div class='container'>
            <div class='text-center'>
              <div class='card mt-3' style={{ width: '20rem' }}>
                <h5 className='card-title bg-dark text-light'>
                  <HijriCalendar />
                </h5>
                <div class='card-body'>
                  <p class='card-text'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <span class='fa-stack fa-3x'>
                          <i class='fas fa-circle fa-stack-2x text-danger'></i>
                          <i class='fas fa-star-and-crescent fa-stack-1x fa-inverse'></i>
                        </span>
                      </div>
                      <div className='col-md-6 text-success'>
                        <RamadanTimings />
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <h2 class='section-heading text-uppercase'>
                {t('page.home.heading1')}
              </h2>
              <h3 class='section-subheading text-muted'>
                {t('page.home.heading.subtext')}
              </h3>
            </div>
            <div class='row text-center'>
              <div class='col-md-4'>
                <span class='fa-stack fa-4x'>
                  <i class='fas fa-circle fa-stack-2x text-success'></i>
                  <i class='fas fa-mosque fa-stack-1x fa-inverse'></i>
                </span>
                <h4 class='my-3'>{t('page.home.section1.heading')}</h4>
                <p class='text-muted'>{t('page.home.section1.text')} </p>
              </div>
              <div class='col-md-4'>
                <span class='fa-stack fa-4x'>
                  <i class='fas fa-circle fa-stack-2x text-warning'></i>
                  <i class='fas fa-seedling fa-stack-1x fa-inverse'></i>
                </span>
                <h4 class='my-3'>{t('page.home.section2.heading')}</h4>
                <p class='text-muted'>{t('page.home.section2.text')}</p>
              </div>
              <div class='col-md-4'>
                <span class='fa-stack fa-4x'>
                  <i class='fas fa-circle fa-stack-2x text-success'></i>
                  <i class='fas fa-map-location-dot fa-stack-1x fa-inverse'></i>
                </span>
                <h4 class='my-3'>{t('page.home.section3.heading')}</h4>
                <p class='text-muted'>{t('page.home.section3.text')}</p>
              </div>
            </div>
          </div>
        </section>
        <section id='scroll'>
          <div class='container px-5'>
            <div class='row gx-5 align-items-center'>
              <div class='col-lg-6 order-lg-2'>
                <div class='p-5'>
                  <img
                    className='img-fluid rounded-circle'
                    src={require('../assets/images/slider/slider-bg-1.jpg')}
                    alt='Madarsa'
                  />
                </div>
              </div>
              <div class='col-lg-6 order-lg-1'>
                <div class='p-5'>
                  <h2 class='display-4'>
                    {t('page.home.below.section.heading')}{' '}
                  </h2>
                  <p>{t('page.home.below.section.text')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home;
