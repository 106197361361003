import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (event) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className='language-selector'>
      <select
        className='form-select form-select mb-3'
        value={selectedLanguage}
        onChange={changeLanguage}
        style={{ color: 'blue', backgroundColor: 'orange' }}
      >
        <option class='dropdown-header'>Select Language:</option>
        <option selected value='ur-IN'>
          اردو
        </option>
        <option value='hi'>हिंदी</option>
        <option value='en'>English</option>
        // Add more languages as needed
      </select>
    </div>
  );
};

export default LanguageSelector;
