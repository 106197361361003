import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import useTranslationHook from '../useTranslationHook';

const Plannings = () => {
  const { t } = useTranslationHook();
  return (
    <div className='container mt-3'>
      <div className='row'>
        <h2>{t('menu.planning')}</h2>
        <nav>
          <ul className='submenu-bar'>
            <li>
              <Link to='future-planning'>
                <button type='button'>
                  {t('menu.planningSub.FuturePlanning')}
                </button>
              </Link>
            </li>
            <li>
              <Link to='current-progress'>
                <button type='button'>
                  {t('menu.planningSub.CurrentProgress')}
                </button>
              </Link>
            </li>
            <li>
              <Link to='funds-expenses'>
                <button type='button'>
                  {t('menu.planningSub.FundsExpenses')}
                </button>
              </Link>
            </li>
          </ul>
        </nav>
        <Outlet /> {/* Placeholder for nested routes */}
      </div>
    </div>
  );
};

export default Plannings;
