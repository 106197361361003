import React, { useState } from 'react';
import BookIcon from './BookIcon';
import PdfReader from './PdfReader';

const books = [
  {
    id: 1,
    title: 'Book 1',
    url: '../assets/books/Quran.pdf',
  },
  { id: 2, title: 'Book 2', url: '../assets/books/Holy-Quran-Urdu.pdf' },
  // Add more book objects here
];

const BookReader = () => {
  const [selectedBookId, setSelectedBookId] = useState(null);

  const handleBookClick = (bookId) => {
    setSelectedBookId(bookId);
  };

  return (
    <div>
      <h1>My PDF Reader</h1>
      <div className='book-icons'>
        {books.map((book) => (
          <BookIcon
            key={book.id}
            bookId={book.id}
            onBookClick={handleBookClick}
          />
        ))}
      </div>
      <PdfReader selectedBookId={selectedBookId} pdfFiles={books} />
    </div>
  );
};

export default BookReader;
