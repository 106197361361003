import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HijriCalendar = () => {
  const [hijriDate, setHijriDate] = useState('');
  const [gregorianDate, setgregorianDate] = useState('');
  let today = new Date();

  let date =
    today.getDate() +
    '-' +
    parseInt(today.getMonth() + 1) +
    '-' +
    today.getFullYear();

  //console.log(date);
  useEffect(() => {
    const fetchHijriDate = async () => {
      try {
        const response = await axios.get(
          `http://api.aladhan.com/v1/gToH/${date}`
        );
        const gregorian = response.data.data.gregorian;
        const hijri = response.data.data.hijri;
        setHijriDate(`${hijri.day} ${hijri.month.ar} ${hijri.year} AH`);
        setgregorianDate(
          `${gregorian.day} ${gregorian.month.en} ${gregorian.year}`
        );
      } catch (error) {
        console.error('Error fetching Hijri date:', error);
      }
    };

    fetchHijriDate();
  }, []);

  return (
    <>
      <div>{gregorianDate}</div>
      <div>{hijriDate}</div>
    </>
  );
};

export default HijriCalendar;
