import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import imageData from '../data/images.json';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');

  useEffect(() => {
    // Simulating fetching data from a local JSON file
    setImages(imageData);
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = (img) => {
    setSelectedImg(img);
    setShow(true);
  };

  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          {images.map((image) => (
            <div key={image.id} className='col-sm-6 col-md-4 col-lg-3 mb-4'>
              <div className='card'>
                <div className='embed-responsive'>
                  <img
                    src={image.url}
                    className='card-img-top img-fluid'
                    alt={image.title}
                    onClick={() => handleShow(image)}
                  />
                </div>
                <div className='card-body'>
                  <h5 className='card-title'>{image.title}</h5>
                  <p className='card-text'>{image.description}</p>
                  <p className='card-text'>
                    <small className='text-muted'>Posted on {image.date}</small>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{selectedImg.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImg.url} alt='Selected' style={{ width: '100%' }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Gallery;
