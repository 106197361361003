import React from 'react';
import useTranslationHook from '../../useTranslationHook';

const VisionMission = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <h2>Vision & Mission</h2>
      <section class='company-mission section-sm bg-gray'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-6'>
              <h3>Our Vision</h3>
              <p className='h5'>{t('page.missionvision.vision.text')}</p>
              <img
                loading='lazy'
                src={require('../../assets/images/donate.jpg')}
                alt=''
                class='img-fluid mt-30'
              />
            </div>
            <div class='col-md-6 mt-5 mt-md-0'>
              <h3>Our Mission</h3>
              <p className='h5'>{t('page.missionvision.mission.text')}</p>
              <img
                loading='lazy'
                src={require('../../assets/images/gate.jpg')}
                alt=''
                class='img-fluid mt-20'
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VisionMission;
