import React from 'react';
import useTranslationHook from '../../useTranslationHook';

const AdvisoryCommittee = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <h2>{t('menu.peopleSub.advisoryCommittee')}</h2>
      <p>{t('page.people.advisoryCommittee.heading1.text')}</p>
      {/* Add additional information about the advisory committee's contributions, members, etc. */}

      <section className='page-section bg-light' id='team'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('page.people.advisoryCommittee.heading2')}
            </h2>
            <h3 className='section-subheading text-muted'>
              {t('page.people.advisoryCommittee.heading2_text')}
            </h3>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='team-member'>
                <img
                  className='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/md younus.jpg')}
                  alt='Md Yunus'
                />
                <h4>Alhaj Master Md Yunus</h4>
                <p className='text-muted'>Advisor</p>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand Twitter Profile'
                >
                  <i className='fab fa-twitter'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand Facebook Profile'
                >
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand LinkedIn Profile'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='team-member'>
                <img
                  className='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/soib.jpg')}
                  alt='shoib'
                />
                <h4>Md Shoib</h4>
                <p className='text-muted'>Advisor</p>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen Twitter Profile'
                >
                  <i class='fab fa-twitter'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen Facebook Profile'
                >
                  <i class='fab fa-facebook-f'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen LinkedIn Profile'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
            <div class='col-lg-4'>
              <div className='team-member'>
                <img
                  class='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/ayub.jpg')}
                  alt='Alhaj Ayub'
                />
                <h4>Alhaj Ayub</h4>
                <p class='text-muted'>Advisor</p>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker Twitter Profile'
                >
                  <i class='fab fa-twitter'></i>
                </a>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker Facebook Profile'
                >
                  <i class='fab fa-facebook-f'></i>
                </a>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker LinkedIn Profile'
                >
                  <i class='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-lg-8 mx-auto text-center'>
              <p class='large text-muted'>
                This page is stil under construction, and any inclusion and
                exclusion of member name is not final or based on any unanimus
                decision. it's just a represental purpose.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AdvisoryCommittee;
