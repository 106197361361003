import React from 'react';
import { useTranslation } from 'react-i18next';
const Donation = () => {
  // Replace these URLs with your actual image URLs
  const { t } = useTranslation();
  return (
    <div className='container mt-5'>
      <div className='row'>
        <div className='col-md-6'>
          <img
            src={require('../assets/images/donate.jpg')}
            alt='Kids and Needy People'
            className='img-fluid mb-3 rounded shadow'
            width={'500'}
            height={'300'}
          />
          <h2>{t('donation.heading1')}</h2>
          {t('donation.text1')}
          <p>Alternatively, you can send your donations directly to:</p>
          <ul>
            <li>
              <strong>UPI ID:</strong> duas@kotak
            </li>
            <li>
              <strong>Bank Account:</strong> Account details for direct
              transfers.
            </li>
          </ul>
        </div>
        <div className='col-md-6 d-flex flex-column align-items-center justify-content-center'>
          <img
            src={require('../assets/images/qrcode.jpeg')}
            alt='Donate via QR Code'
            className='img-fluid mb-3 rounded shadow'
            width={300}
            height={400}
          />
          <p>Scan the QR code to donate easily via your phone.</p>
        </div>
      </div>
    </div>
  );
};

export default Donation;
