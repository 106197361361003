import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/custom.css'; // Assuming you create a custom CSS file for the Footer
import useTranslationHook from '../useTranslationHook';

const Footer = () => {
  const { t } = useTranslationHook();
  return (
    <footer id='footer' className='footer text-white py-3 mt-auto bg-one'>
      <div className='top-footer'>
        <div className='container'>
          <div className='row'>
            {/* Footer Links */}
            <div className='col-lg-6 col-md-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h5 className='fw-bold text-decoration-underline mb-2'>
                    Pages
                  </h5>
                  <ul className='list-unstyled'>
                    <li>
                      <Link to='/' className='footer-link'>
                        {t('menu.home')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/about' className='footer-link'>
                        {t('menu.about')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/people' className='footer-link'>
                        {t('menu.people')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className='col-sm-6'>
                  <ul className='list-unstyled'>
                    <li>
                      <Link to='/plannings' className='footer-link'>
                        {t('menu.planning')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/donation' className='footer-link'>
                        {t('menu.donation')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/contact' className='footer-link'>
                        {t('menu.contact')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/gallery' className='footer-link'>
                        {t('menu.gallery')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Contact Information */}
            <div className='col-lg-6 col-md-12'>
              <h5 className='fw-bold text-decoration-underline mb-2'>
                {t('menu.contact')}
              </h5>
              <address className='mb-0'>
                <p>
                  {t('address.line1')}
                  <br />
                  {t('address.line2')}
                  <br />
                  <abbr title='Phone'>P:</abbr> +91-702-115-4715
                  <br />
                  <a
                    href='mailto:duas.nikhra@gmail.com'
                    className='footer-link'
                  >
                    duas.nikhra@gmail.com
                  </a>
                </p>
              </address>
            </div>
          </div>
        </div>

        <div className='border-top pt-3 footer-bottom'>
          <p className='mb-0 small'>
            © {new Date().getFullYear()} Madrasa Ashrafia Siddiquia Nikhra. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
