import React from 'react';
import useTranslationHook from '../../useTranslationHook';

const ManagingCommittee = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <h2>{t('menu.peopleSub.managingCommittee')}</h2>
      <p>{t('page.people.managingCommittee.heading1_Text')}</p>
      {/* You can add more content, such as a list of committee members, their roles, etc. */}
      <section className='page-section bg-light' id='team'>
        <div className='container'>
          <div className='text-center'>
            <h2 className='section-heading text-uppercase'>
              {t('page.people.managingCommittee.heading2')}
            </h2>
            <h3 className='section-subheading text-muted'>
              {t('page.people.managingCommittee.heading2_text')}
            </h3>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='team-member'>
                <img
                  className='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/azaz.jpg')}
                  alt='Head Managing Committee'
                />
                <h4>Er. Mohammad Azaz</h4>
                <p className='text-muted'>Managing committee</p>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand Twitter Profile'
                >
                  <i className='fab fa-twitter'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand Facebook Profile'
                >
                  <i className='fab fa-facebook-f'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Parveen Anand LinkedIn Profile'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4'>
              <div className='team-member'>
                <img
                  className='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/arshad.jpg')}
                  alt='...'
                />
                <h4>Mufti Arshad</h4>
                <p className='text-muted'>Manging committee</p>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen Twitter Profile'
                >
                  <i class='fab fa-twitter'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen Facebook Profile'
                >
                  <i class='fab fa-facebook-f'></i>
                </a>
                <a
                  className='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Diana Petersen LinkedIn Profile'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
            <div class='col-lg-4'>
              <div className='team-member'>
                <img
                  class='mx-auto rounded-circle'
                  src={require('../../assets/images/gallary/members/imtiyaz.jpg')}
                  alt='...'
                />
                <h4>Er. Md. Imtiyaz Ali</h4>
                <p class='text-muted'>Manging Committee</p>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker Twitter Profile'
                >
                  <i class='fab fa-twitter'></i>
                </a>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker Facebook Profile'
                >
                  <i class='fab fa-facebook-f'></i>
                </a>
                <a
                  class='btn btn-dark btn-social mx-2'
                  href='#!'
                  aria-label='Larry Parker LinkedIn Profile'
                >
                  <i class='fab fa-linkedin-in'></i>
                </a>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-lg-8 mx-auto text-center'>
              <p class='large text-muted'>
                {t('page.people.managingCommittee.reponsibilities')}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ManagingCommittee;
