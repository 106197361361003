import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import useTranslationHook from '../useTranslationHook';

const People = () => {
  const { t } = useTranslationHook();
  return (
    <div className='container mt-3'>
      <div className='row'>
        <ul className='submenu-bar'>
          <li class>
            <Link to='doner'>
              <button type='button'>{t('menu.peopleSub.doner')}</button>
            </Link>
          </li>
          <li>
            <Link to='managing-committee'>
              <button>{t('menu.peopleSub.managingCommittee')}</button>
            </Link>
          </li>
          <li>
            <Link to='advisory-committee'>
              <button>{t('menu.peopleSub.advisoryCommittee')}</button>
            </Link>
          </li>
          <li>
            <Link to='working-committee'>
              <button>{t('menu.peopleSub.workingCommittee')}</button>
            </Link>
          </li>
        </ul>
      </div>
      <Outlet /> {/* Placeholder for nested routes */}
    </div>
  );
};

export default People;
