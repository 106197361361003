import React from 'react';

const BookIcon = ({ bookId, onBookClick }) => {
  return (
    <button type='button' onClick={() => onBookClick(bookId)}>
      {/* Replace with your book icon image */}
      <img
        src={require('../assets/images/book-icon/theMiraclesOfQuran.jpg')}
        alt='Book Icon'
      />
    </button>
  );
};

export default BookIcon;
