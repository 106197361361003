import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import useTranslationHook from '../useTranslationHook';

const About = () => {
  const { t } = useTranslationHook();
  return (
    <div className='container mt-3'>
      <nav>
        <ul className='submenu-bar'>
          <li>
            <Link to='history'>
              <button type='button'>{t('menu.aboutSub.history')}</button>
            </Link>
          </li>
          <li>
            <Link to='vision-mission'>
              <button type='button'>{t('menu.aboutSub.visionMission')}</button>
            </Link>
          </li>
          <li>
            <Link to='news'>
              <button type='button'>{t('menu.aboutSub.news')}</button>
            </Link>
          </li>
          <li>
            <Link to='latest-update'>
              <button type='button'>{t('menu.aboutSub.latest_update')}</button>
            </Link>
          </li>
        </ul>
      </nav>
      <h2 className='mt-5'>{t('page.about.heading_main')}</h2>
      <Outlet /> {/* Placeholder for nested routes */}
    </div>
  );
};

export default About;
