import React from 'react';
import useTranslationHook from '../../useTranslationHook';

const WorkingCommittee = () => {
  const { t } = useTranslationHook();
  return (
    <div>
      <h2>{t('page.people.workingCommittee.heading')}</h2>
      <p>{t('page.people.workingCommittee.text1')}</p>
      {/* Further details about the committee's current projects, achievements, and member profiles can be added here. */}
      <div class='row'>
        <div class='col-lg-4 col-md-6'>
          <div class='team-member text-center'>
            <div class='member-photo'>
              <img
                loading='lazy'
                class='img-fluid'
                src={require('../../assets/images/gallary/members/halim.jpg')}
                alt='Md Halim'
              />

              <div class='mask'>
                <ul class='clearfix'>
                  <li>
                    <a href='#'>
                      <i class='tf-ion-social-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <i class='tf-ion-social-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <i class='tf-ion-social-google-outline'></i>
                    </a>
                  </li>
                  <li>
                    <a href='#'>
                      <i class='tf-ion-social-dribbble'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class='member-content'>
              <h3>Master Md Halim</h3>
              <h4>General Secratory</h4>
              <p>
                Past 1 year he is working as a secratory, and has very good
                experience to manage the institute and staffs.
              </p>
            </div>
          </div>
        </div>

        <div class='col-lg-4 col-md-6'>
          <div class='team-member text-center'>
            <div class='member-photo'>
              <img
                loading='lazy'
                class='img-fluid'
                src={require('../../assets/images/gallary/members/mashique.jpg')}
                alt='Mashiqur rahman'
              />

              <div class='mask'>
                <ul class='clearfix'>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-google-outline'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-dribbble'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class='member-content'>
              <h3>Md Mashique-Ur-Rahman</h3>
              <h4>Leader</h4>
              <p>One of the most active working member of the committee.</p>
            </div>
          </div>
        </div>

        <div class='col-lg-4 col-md-6'>
          <div class='team-member text-center'>
            <div class='member-photo'>
              <img
                loading='lazy'
                class='img-fluid'
                src={require('../../assets/images/gallary/members/asrar.jpg')}
                alt='Asrar'
              />

              <div class='mask'>
                <ul class='clearfix'>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-google-outline'></i>
                    </a>
                  </li>
                  <li>
                    <a href='https://themefisher.com/'>
                      <i class='tf-ion-social-dribbble'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class='member-content'>
              <h3>Md Asrar</h3>
              <h4>Member</h4>
              <p>Very much motivated and well educated youth of the village.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingCommittee;
