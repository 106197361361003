import React, { useState, useEffect } from 'react';
import useTranslationHook from '../../useTranslationHook';
import taskData from '../../data/tasks.json';

const FuturePlanning = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    // Simulating fetching data from a local JSON file
    setTasks(taskData);
  }, []);

  const { t } = useTranslationHook();
  return (
    <div>
      <h3>{t('menu.planningSub.FuturePlanning')}</h3>
      <div className='container mt-3'>
        <h2 className='text-center mb-4'>Future Planning List</h2>
        <ul className='list-group'>
          {tasks.map((task) => (
            <li
              key={task.id}
              className={`list-group-item ${
                task.status === 'done'
                  ? 'text-success'
                  : task.status === 'current'
                  ? 'text-primary'
                  : ''
              }`}
              style={{
                textDecoration:
                  task.status === 'done' ? 'line-through' : 'none',
              }}
            >
              {task.task}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default FuturePlanning;
